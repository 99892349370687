<template>
  <li :class="{'is-active':isDropdownActive}">
    <a v-if="isMenu"
      @click="menuClick"
      :class="componentClass"
      :href="item.href">
      <b-icon
        v-if="item.imagen"
        :icon="item.imagen"
        :class="{ 'has-update-mark' : item.updateMark }"
        custom-size="default"
      />
      <span v-if="item.label" :class="{'menu-item-label':!!item.imagen}">{{ item.label }}</span>
      <div v-if="hasSubmenuIcon" class="submenu-icon">
        <b-icon :icon="submenuIcon" custom-size="default"/>
      </div>
    </a>
    <router-link v-else
      :to="itemTo"
      :title="componentTitle"
      :exact-active-class="componentActiveClass"
      :class="componentClass">
      <b-icon
        v-if="item.imagen"
        :icon="item.imagen"
        :class="{ 'has-update-mark' : item.updateMark }"
        custom-size="default"
      />
      <span v-if="item.label" :class="{'menu-item-label':!!item.imagen}">{{ item.label }}</span>
      <div v-if="hasSubmenuIcon" class="submenu-icon">
        <b-icon :icon="submenuIcon" custom-size="default"/>
      </div>
    </router-link>
    <aside-menu-list
        v-if="hasDropdown"
        :menu="item.subMenus"
        :isSubmenuList="true"/>
  </li>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AsideMenuItem',
  props: {
    item: {
      type: Object,
      default: null
    },
    isSecondary: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isDropdownActive: false
    }
  },
  methods: {
    menuClick () {
      this.$emit('menu-click', this.item)
      if (this.hasDropdown) {
        this.isDropdownActive = (!this.isDropdownActive)

        if (!this.isSecondary && !this.isAsideMobileExpanded) {
          this.$store.commit('asideStateToggle', true)
        }
      }
    }
  },
  computed: {
    isMenu () {
      return ['MENU', 'EXTERNAL'].includes(this.item.tipoMenu)
    },
    componentIs () {
      return ['MENU', 'EXTERNAL'].includes(this.item.tipoMenu) ? 'a' : 'router-link'
    },
    hasSubmenuIcon () {
      return this.hasDropdown || this.item.menuSecondary
    },
    hasDropdown () {
      // return !!this.item.menu
      return !!this.item.subMenus
    },
    submenuIcon () {
      if (this.item.menuSecondary) {
        return 'chevron-right'
      }
      return (this.isDropdownActive) ? 'minus' : 'plus'
    },
    itemTo () {
      switch (this.item.tipoMenu) {
        case 'WPanel':
          return this.item.dataProvider ? '/ventana/' + this.item.dataProvider : ''
        case 'WPanelDynamic':
          return this.item.dataProvider ? '/dynamic/' + this.item.dataProvider : ''
        case 'WGridCelulas':
          return '/estadoCelulas'
        case 'WGantt':
          return '/gantt'
        case 'VisorExpediciones':
          return '/visorExpediciones'
        default:
          return ''
      }
    },
    itemHref () {
      return this.item.href ? this.item.href : null
    },
    componentTitle () {
      return !this.isAsideExpanded && this.item.label ? this.item.label : null
    },
    componentClass () {
      const c = {
        'has-icon': !!this.item.imagen,
        'has-submenu-icon': this.hasSubmenuIcon
      }

      if (this.item.state) {
        c['is-state-' + this.item.state] = true
        c['is-hoverable'] = true
      }

      if (this.asideActiveForcedKey && this.item.menuSecondaryKey && this.asideActiveForcedKey === this.item.menuSecondaryKey) {
        c['is-active'] = true
      }

      return c
    },
    componentActiveClass () {
      if (this.asideActiveForcedKey) {
        return null
      }
      return 'is-active'
    },
    ...mapState([
      'asideActiveForcedKey',
      'isAsideExpanded'
    ])
  },
  watch: {
    isAsideExpanded (newValue) {
      if (!newValue) {
        this.isDropdownActive = false
      }
    }
  }
}
</script>
<style scoped>
  aside.aside .menu-list li ul li a {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
</style>
