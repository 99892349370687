<template>
  <div class="full-height">
    <title-bar :title-stack="titleStack"/>
    <div class="section">
      <section v-if="showByPerm('dashboard')">
        <card-component title="Pedidos de cliente">
          <div class="filter-card">
            <b-field grouped>
              <b-field label="Cliente">
                <WTags
                  :key="filterKey"
                  :campo="getCustomers()"
                  procedimiento='p_webAndon'
                  v-model="filter.customer"
                  @click.native.stop=""
                  @input="obtenerPedidosCliente()"
                ></WTags>
              </b-field>
              <b-field label="Fechas">
                <WDayRangeFilter
                  :key="filterKey"
                  v-model="filter.date"
                  @click.native.stop=""
                  @input="obtenerPedidosCliente()"
                ></WDayRangeFilter>
              </b-field>
              <b-field label="Reset" key="reset">
                <b-button
                  type="is-info"
                  class="button"
                  icon-left="card"
                  @click="resetFilter()"
                ></b-button>
              </b-field>
            </b-field>
          </div>
          <HorizontalStackedBarChart
            style="height: 1000px"
            :key="barChartKey"
            :chartData="chartData"
            :chartOptions="chartOptions"
          />
        </card-component>
      </section>

      <section class="dashboard-section" v-for="(seccion, key) in secciones" :key="key">
        <card-component :title="seccion.titulo" v-if="showByPerm(seccion.permiso)">
          <Charts
            :accion="seccion.accion"
            :accionSummary="seccion.accionSummary"
            :options="seccion.options"
            :data="seccion.data"
          ></Charts>
        </card-component>
      </section>

      <section class="dashboard-section">
        <card-component title="Fecha propuesta vs Fecha real" v-if="showByPerm('dashboard')">
            <div class="filter-card">
              <b-field grouped>
                <b-field label="Cliente">
                  <WTags
                    :key="filterKey"
                    :campo="getPlantas()"
                    procedimiento='p_webAndon'
                    v-model="filter.planta"
                    @click.native.stop=""
                    @input="obtenerPromedioPlanificacion()"
                  ></WTags>
                </b-field>
              </b-field>
            </div>
            <b-table
              :data="tablaFechaPropuesta.datos"
              :columns="tablaFechaPropuesta.columnas"
              :loading="tablaFechaPropuesta.isLoading"
              :paginated="true"
              :per-page="10"
              :current-page.sync="tablaFechaPropuesta.pagina"
              :show-detail-icon="true"
              detailed
            >
              <template #detail="props">
                <table class="table-detail">
                  <tr>
                    <th>Agrupacion</th>
                    <th>Propuesta original</th>
                    <th>Fecha propuesta</th>
                    <th>Fecha asignada</th>
                    <th>Promedio</th>
                  </tr>
                  <tr v-for="(values, key) in props.row.detail" :key="key">
                    <td>{{ values.idViaje }}</td>
                    <td>{{ values.propuestaOriginal }}</td>
                    <td>{{ values.fechaPropuesta }}</td>
                    <td>{{ values.fechaAsignada }}</td>
                    <td>{{ values.promedio }}</td>
                  </tr>
                </table>
              </template>
            </b-table>
        </card-component>
      </section>
    </div>
  </div>
</template>

<script>
import CardComponent from '@/components/template/CardComponent'
import TitleBar from '@/components/template/TitleBar'
import WTags from '@/components/forms/WTags'
import WDayRangeFilter from '@/components/forms/WDayRangeFilter'
import Charts from '@/components/template/Charts'
import HorizontalStackedBarChart from '@/components/Charts/HorizontalStackedBarChart.vue'

export default {
  name: 'Home',
  components: {
    CardComponent,
    TitleBar,
    WTags,
    WDayRangeFilter,
    Charts,
    HorizontalStackedBarChart
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      },
      chartData: {},
      titleStack: ['Admin', 'Dashboard'],
      barChartKey: 0,
      filterKey: 0,
      filter: {
        date: null,
        customer: null,
        planta: null
      },
      tablaFechaPropuesta: {
        key: 0,
        isLoading: false,
        pagina: 1,
        columnas: [
          {
            field: 'planta',
            label: 'Planta'
          },
          {
            field: 'promedio',
            label: 'Promedio'
          }
        ],
        datos: [],
        filtros: {
          planta: {
            key: 0,
            valor: null
          }
        }
      },
      secciones: [
        {
          permiso: 'dashboard_viajes_pendientes',
          titulo: 'Viajes pendientes',
          accion: 'VIAJES_PENDIENTES',
          options: {
            tipo: 'multiBarChart',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            header: [
              {
                type: 'label',
                label: 'Fecha',
                filter: 'daterange',
                field: 'fecha'
              },
              {
                type: 'group',
                field: 'cargadero'
              },
              {
                type: 'dataset',
                field: 'total'
              }
            ]
          }
        },
        {
          permiso: 'dashboard_viajes_modificados',
          titulo: 'Total viajes modificados',
          accion: 'VIAJES_MODIFICADOS',
          options: {
            tipo: 'table',
            isStriped: true,
            isNarrow: true,
            isHoverable: true,
            isLoading: false,
            useMobileCards: true,
            paginated: true,
            perPage: 10
          },
          data: {
            filters: [
              {
                filter: 'string',
                label: 'Cliente',
                field: 'cliente'
              },
              {
                filter: 'string',
                label: 'Mes',
                field: 'mes'
              },
              {
                filter: 'string',
                label: 'Año',
                field: 'anyo'
              }
            ],
            columns: [
              {
                field: 'cliente',
                label: 'Cliente'
              },
              {
                field: 'fecha',
                label: 'Fecha'
              },
              {
                field: 'total',
                label: 'Total'
              }
            ]
          }
        },
        {
          permiso: 'dashboard_viajes_pendientes',
          titulo: 'Viajes pendientes (M3)',
          accion: 'VIAJES_PENDIENTES_M3',
          options: {
            tipo: 'multiBarChart',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            header: [
              {
                type: 'label',
                label: 'Fecha',
                filter: 'daterange',
                field: 'fecha'
              },
              {
                type: 'group',
                field: 'cargadero'
              },
              {
                type: 'dataset',
                field: 'total'
              }
            ]
          }
        },
        {
          permiso: 'dashboard_viajes_servidos',
          titulo: 'Viajes servidos',
          accion: 'SERVIDOS',
          accionSummary: 'SERVIDOS_SUMMARY',
          options: {
            tipo: 'multiBarChart',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            header: [
              {
                type: 'label',
                label: 'Fecha',
                filter: 'daterange',
                field: 'fecha'
              },
              {
                type: 'group',
                field: 'cargadero'
              },
              {
                type: 'dataset',
                field: 'total'
              }
            ],
            summary: [
              {
                label: 'Total pedidos',
                field: 'totalPedidos',
                value1: null,
                field2: 'diferenciaPedidos',
                value2: null
              },
              {
                label: 'Total servido (m<sup>3</sup>)',
                field: 'totalServido',
                value1: null,
                field2: 'diferenciaServidor',
                value2: null
              }
            ]
          }
        },
        {
          permiso: 'dashboard_recursos_usados',
          titulo: 'Recursos usados',
          accion: 'RECURSOS_USADOS',
          options: {
            tipo: 'multiBarChart',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            header: [
              {
                type: 'label',
                label: 'Fecha',
                filter: 'daterange',
                field: 'fecha'
              },
              {
                type: 'group',
                label: 'Cargadero',
                filter: 'select',
                action: 'CARGADEROS_RECURSOS_USADOS',
                procedure: 'p_webAndon',
                multiple: true,
                field: 'cargadero'
              },
              {
                type: 'dataset',
                label: 'TOTAL',
                field: 'total'
              }
            ]
          }
        },
        {
          permiso: 'dashboard_dias_servidos',
          titulo: 'M3 por dia servidos',
          accion: 'M3_DIAS_SERVIDOS',
          options: {
            tipo: 'lineChart',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true
            },
            header: [
              {
                type: 'label',
                label: 'Fecha',
                filter: 'daterange',
                field: 'fecha'
              },
              {
                type: 'dataset',
                field: 'media',
                label: 'M3 MEDIA',
                backgroundColor: '#f87979'
              },
              {
                label: 'Cargadero',
                field: 'cargadero',
                filter: 'select',
                action: 'CARGADEROS_DIAS_SERVIDOS',
                procedure: 'p_webAndon',
                multiple: true
              }
            ]
          }
        }
      ]
    }
  },
  mounted () {
    this.obtenerPedidosCliente()
    this.obtenerPromedioPlanificacion()
  },
  computed: {
    permisos () {
      return this.$store.state.permisos
    }
  },
  methods: {
    getCustomers () {
      let f = { accionValores: 'CHART_DATA_CLIENTES' }
      return f
    },
    async obtenerPedidosCliente () {
      try {
        if (this.showByPerm('dashboard')) {
          var accion = 'CHART_DATA'
          var params = {
            accion: accion,
            fechaInicio: this.filter.date ? this.filter.date[0] : '',
            fechaFinal: this.filter.date ? this.filter.date[1] : '',
            cliente: this.filter.customer ? this.filter.customer : ''
          }
          try {
            this.$api.proc.put('p_webAndon', params).then((response) => {
              if (response && response.data) {
                const { data } = response
                this.chartData = {
                  labels: [ ...new Set(data.map(item => { return item.cliente })) ],
                  datasets: [
                    {
                      label: 'En plazo',
                      backgroundColor: '#3BD5CC',
                      data: data.filter(item => { return item.dataset === 'EN PLAZO' }).map(item => { return Number(item.total) })
                    },
                    {
                      label: 'Fuera de plazo',
                      backgroundColor: '#EE5934',
                      data: data.filter(item => { return item.dataset === 'FUERA DE PLAZO' }).map(item => { return Number(item.total) })
                    }
                  ]
                }
              }
            })
          } catch (error) {
            // console.error(error)
          }
        }
      } catch (error) {
        // console.error(error) // Uncomment to debug
      }
    },
    showByPerm (perm) {
      let index = this.permisos.findIndex(item => item === perm)
      if (index > -1) { return true }
      return false
    },
    resetFilter () {
      this.filter = { date: null, customer: null }
      this.filterKey += 1 // Reset the filter components
      this.obtenerPedidosCliente() // Reload the chart
    },
    forceChartRerender () {
      this.barChartKey += 1
    },
    async obtenerPromedioPlanificacion () {
      if (this.showByPerm('dashboard')) {
        try {
          this.tablaFechaPropuesta.isLoading = true
          var params = {
            accion: 'FECHA_PROPUESTA',
            planta: this.filter.planta ? this.filter.planta : ''
          }
          this.$api.proc.put('p_webAndon', params).then((response) => {
            if (response && response.data) {
              const { data } = response
              this.tablaFechaPropuesta.key += 1

              var arrayData = []
              data.filter(item => item.cabecera === '1').forEach((item, index) => {
                arrayData[index] = item
                arrayData[index].detail = data.filter(element => element.cabecera === '0' && element.planta === item.planta)
              })

              this.tablaFechaPropuesta.datos = arrayData
              this.tablaFechaPropuesta.isLoading = false
            }
          })
        } catch (error) {
          // console.error(error) // Uncomment to debug
        }
      }
    },
    getPlantas () {
      let f = { accionValores: 'FECHA_PROPUESTA_PLANTAS' }
      return f
    }
  }
}
</script>

<style>
  .dashboard-section {
    margin: 15px;
  }
  .table-detail {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
</style>
