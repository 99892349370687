<template>
  <ul :class="{'menu-list':!isSubmenuList}">
    <aside-menu-item v-for="(item,index) in filteredMenu"
      @menu-click="menuClick"
      :is-secondary="isSecondary"
      :item="item"
      :key="index"/>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import AsideMenuItem from '@/components/template/AsideMenuItem'

export default {
  name: 'AsideMenuList',
  components: {
    AsideMenuItem
  },
  props: {
    isSubmenuList: {
      type: Boolean,
      default: false
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState([
      'permisos'
    ]),
    filteredMenu () {
      return this.menu.filter(el => {
        return this.permisos.some(f => {
          return f === el.permiso
        })
      })
    }
  },
  methods: {
    menuClick (item) {
      this.$emit('menu-click', item)
    }
  }
}
</script>
