<template>
  <div :key="resetChart">
    <div class="columns is-vcentered">
      <div class="column">
        <div class="filter-card">
          <b-field grouped>
            <b-field v-for="(item, key) in getFilters(options, data)" :label="item.label" :key="key">
              <b-input
                v-if="item.filter === 'string'"
                size="is-small"
                v-model="item.filterValue"
                @input="updateParameter(item, key)"
                @keyup.enter.native="getData()"
              ></b-input>
              <WDayRangeFilter
                v-if="item.filter === 'daterange'"
                v-model="item.filterValue"
                @input="updateParameter(item, key)"
              ></WDayRangeFilter>
              <WSelectFilter
                v-if="item.filter === 'select'"
                :select="getFilterSelect(item.field)"
                v-model="item.filterValue"
                @click.native.stop=""
                @input="updateParameter(item, key)"
              ></WSelectFilter>
            </b-field>
            <div class="container">
              <b-button
                class="element"
                size="is-small"
                type="is-info"
                icon-right="apps"
                @click="reset()"
              ></b-button>
            </div>
          </b-field>
        </div>
      </div>
    </div>
    <!-- Summary -->
    <div v-if="options.summary" :key="resetSummary" class="summary-box box">
      <div class="summary-item" v-for="(item, key) in getSummary()" :key="key">
        <div
          class="label has-text-weight-bold"
          v-html="item.label"
        ></div>
        <div
          class="value is-size-4 has-text-weight-semibold"
          v-html="item.value"
        ></div>
        <div v-if="item.value2" :class="getSummaryClass(item.value2)">
          <span>{{ item.value2 }}%</span>
        </div>
      </div>
    </div>
    <div>
      <b-table v-if="options.tipo === 'table'"
        :data="chartData"
        :columns="data.columns"
        :is-striped="options.isStriped"
        :is-narrow="options.isNarrow"
        :is-hoverable="options.isHoverable"
        :is-loading="isLoading"
        :use-mobile-cards="options.useMobileCards"
        :paginated="options.paginated"
        :per-page="options.perPage"
        :detailed="options.detailed"
      >
        <template #empty>
          <p v-if="isLoading" class="has-text-centered">
            Cargando datos...
          </p>
          <p v-else class="has-text-centered">
            No se han encontrado resultados
          </p>
        </template>
      </b-table>
      <div v-if="options.tipo === 'lineChart'">
        <line-chart
          :key="key"
          :chartData="chartData"
          :chartOptions="options"
        ></line-chart>
      </div>
      <div v-if="options.tipo === 'multiBarChart'">
        <MultiBarChart
          :key="key"
          :data="chartData"
          :chartOptions="options"
        ></MultiBarChart>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart'
import MultiBarChart from '@/components/Charts/MultiBarChart'
import WDayRangeFilter from '@/components/forms/WDayRangeFilter'
import WSelectFilter from '@/components/forms/WSelectFilter'
export default {
  name: 'Charts',
  components: {
    LineChart,
    MultiBarChart,
    WDayRangeFilter,
    WSelectFilter
  },
  data () {
    return {
      isLoading: false,
      chartData: [],
      summaryData: {},
      search: [],
      resetChart: 0,
      resetSummary: 0,
      key: 0
    }
  },
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    tipo: {
      type: String,
      default: ''
    },
    accion: {
      type: String,
      default: ''
    },
    accionSummary: {
      type: String,
      default: ''
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        this.isLoading = true
        var params = { accion: this.accion }
        if (Object.keys(this.search).length > 0) {
          for (let index in this.search) {
            let value = this.search[index].value
            let field = this.search[index].field
            if (Array.isArray(value)) {
              params[field] = value[0]
              params[field + '_FIN'] = value[1]
            } else {
              params[field] = value
            }
          }
        }
        this.$api.proc.put('p_webAndon', params).then((response) => {
          if (response && response.data) {
            const { data } = response
            if (this.data.detailed) {
              var arrayData = []
              const parentLabel = this.data.columns.find(item => item.parent).field
              data.filter(item => item.cabecera === '1').forEach((element, index) => {
                arrayData[index] = element
                arrayData[index]['detail'] = data.filter(item => item.cabecera === '0' && element[parentLabel] === item[parentLabel])
              })
              this.chartData = arrayData
            } else {
              this.chartData = data
            }
            this.key += 1
          }
        })
      } catch (error) {
        // console.error(error)
        this.chartData = []
      } finally {
        this.isLoading = false
        this.getSummaryData()
      }
    },
    async getSummaryData () {
      if (this.accionSummary) {
        var params = { accion: this.accionSummary }
        if (Object.keys(this.search).length > 0) {
          for (let index in this.search) {
            let value = this.search[index].value
            let field = this.search[index].field
            if (Array.isArray(value)) {
              params[field] = value[0]
              params[field + '_FIN'] = value[1]
            } else {
              params[field] = value
            }
          }
        }
        this.$api.proc.put('p_webAndon', params).then((response) => {
          if (response && response.data) {
            const { data } = response
            this.summaryData = data[0]
            this.resetSummary++
          }
        })
      }
    },
    getFilters (options, data) {
      if (options.tipo === 'table') {
        return data.filters
      }
      const header = options.header
      return header.filter(item => item.filter)
    },
    updateParameter (filter, key) {
      let index = this.search.findIndex(item => item.field === filter.field)
      if (index > -1) {
        this.search[index].value = filter.filterValue
      } else {
        this.search.push({
          field: filter.field,
          type: filter.filter,
          value: filter.filterValue,
          key: key
        })
      }
      if (filter.filter === 'daterange' || filter.filter === 'select') {
        this.getData()
      }
    },
    getFilterSelect (field) {
      if (this.options.tipo === 'barChart' || this.options.tipo === 'multiBarChart' || this.options.tipo === 'lineChart') {
        var column = this.options.header.find(item => item.field === field)
      }
      if (column) {
        let f = {
          field: field,
          filtro: column.action,
          procedimiento: column.procedure,
          valorInicial: null,
          multiple: column.multiple
        }
        return f
      }
      return {}
    },
    reset () {
      this.search = []
      this.getFilters(this.options, this.data).forEach(item => { item.filterValue = null })
      this.resetChart += 1
      this.getData()
    },
    getSummary () {
      if (this.accionSummary) {
        var summary = this.options.summary.map(item => {
          try {
            var value = this.summaryData[item.field]
            var value2 = this.summaryData[item.field2]
          } catch (e) {
            value = 0
            value2 = 0
          }
          return {
            label: item.label,
            value: this.formatNumber(value),
            value2: this.formatNumber(value2)
          }
        })
        return summary
      }
    },
    getSummaryClass (value) {
      if (value < 0) {
        return 'difference is-size-6 has-text-danger'
      }
      return 'difference is-size-6 has-text-success'
    },
    formatNumber (number) {
      try {
        var value = Number(number)
        return value
      } catch (error) {
        // console.error(error)
        return 0
      }
    }
  }
}
</script>

<style scoped>
  .container{
    position: relative;
  }

  .element{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .summary-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 15px;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    border: none;
    box-shadow: none;
  }

  .summary-item {
    background-color: #f2f2f2;
    padding: 20px;
  }

  .label {
    font-size: 18px;
  }

  .value {
    font-size: 24px;
  }
</style>
