<template>
  <b-taginput
    v-model="valorMostrar"
    :placeholder="campo.placeholder"
    :allow-new="this.campo.insertarNuevo"
    autocomplete
    :required="campo.required"
    :data="filteredDataArray"
    :loading='isLoading'
    expanded
    keep-first
    open-on-focus
    @typing="getFilteredData"
  >
  </b-taginput>
</template>

<script>
export default {
  name: 'WTags',
  props: {
    campo: Object,
    valor: {
      type: String,
      default: ''
    },
    procedimiento: String,
    idParent: String
  },
  data () {
    return {
      isLoading: false,
      options: [],
      escrito: '',
      filteredDataArray: []
    }
  },
  beforeMount () {
    this.loadOptions()
  },
  computed: {
    valorMostrar: {
      get: function () {
        let arr = this.valor.split(',')
        if (arr.length === 0 || (arr.length === 1 && arr[0] === '')) {
          return []
        } else {
          return arr
        }
      },
      set: function (valorNuevo) {
        this.escrito = valorNuevo
        this.$emit('input', valorNuevo.join())
      }
    }
  },
  methods: {
    getFilteredData (text) {
      this.filteredDataArray = this.options.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    async loadOptions () {
      try {
        this.isLoading = true
        if (this.campo.accionValores) {
          this.options = []
          if (this.campo.accionValores.toUpperCase() === 'SI_NO') {
            this.options = ['SI', 'NO']
            return
          }
          const params = {}
          let proc = 'p_select'
          if (this.procedimiento !== '') {
            params.accion = this.campo.accionValores
            proc = this.procedimiento
          } else {
            params.tabla = this.campo.accionValores
          }
          if (this.idParent !== '') {
            params.idParent = this.idParent
          }
          this.$api.proc.put(proc, params).then((response) => {
            if (response && response.data) {
              const { data } = response
              for (var i = 0; i < data.length; i++) {
                this.options.push(Object.values(data[i])[0])
              }
            }
          })
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>

</style>
