<template>
  <b-dropdown v-model="selectedOptions" :loading='isLoading' size="is-small" :multiple="select.multiple">
    <button class="button is-small" slot="trigger" slot-scope="{ active }">
      <span>{{showValue}}</span>
      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
    </button>
    <b-dropdown-item v-for="option in options" :key="option" :value="option" @click="$emit('input', inputVal)">
      {{ option }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'WSelectFilter',
  props: {
    select: {
      field: String,
      filtro: String,
      procedimiento: String,
      multiple: Boolean,
      valorInicial: Number
    },
    value: String,
    idParent: String
  },
  computed: {
    showValue () {
      var option
      if (this.select.multiple) {
        if (this.value && this.value.length > 0) {
          option = this.value
        } else {
          option = this.selectedOptions
        }
        if (this.selectedOptions.length === 0) {
          return ''
        } else if (this.selectedOptions.length === 1) {
          return this.selectedOptions[0]
        } else {
          return 'MULTIPLE'
        }
      } else {
        if (this.value) {
          option = this.value
        } else {
          option = this.selectedOptions
        }
        if (Array.isArray(option)) {
          return ''
        } else {
          return option
        }
      }
    },
    inputVal: {
      get () {
        if (this.select.multiple) {
          return this.selectedOptions.join()
        } else {
          return this.selectedOptions
        }
      },
      set (val) {
        if (this.select.multiple) {
          this.selectedOptions = val.split(',')
        } else {
          this.selectedOptions = val
        }
      }
    }
  },
  data () {
    return {
      isLoading: false,
      options: {},
      selectedOptions: []
    }
  },
  beforeMount () {
    this.loadOptions()
  },
  methods: {
    async loadOptions () {
      try {
        this.isLoading = true
        if (this.select.filtro && this.select.filtro.toUpperCase() === 'SI_NO') {
          this.options = ['SI', 'NO']
          return
        }

        const params = {}
        this.options = []
        let proc = 'p_select'

        if (this.select.procedimiento !== '') {
          params.accion = this.select.filtro
          proc = this.select.procedimiento
        } else {
          params.tabla = this.select.filtro
        }

        if (this.idParent !== '') {
          params.idParent = this.idParent
        }

        this.$api.proc.put(proc, params).then((response) => {
          if (response && response.data) {
            const { data } = response
            for (var i = 0; i < data.length; i++) {
              this.options.push(Object.values(data[i])[0])
            }
          }
        })
        /*
        if (this.select.valorInicial && this.select.valorInicial > -1 && this.options.length > 0 && this.options[this.select.valorInicial]) {
          this.$emit('input', this.options[this.select.valorInicial])
        }
        */
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger'
        })
      } finally {
        this.isLoading = false
      }
    },
    clear () {
      this.selectedOptions = []
    }
  },
  watch: {
    value: function () {
      if (this.value === undefined) {
        this.selectedOptions = []
      }
    }
  }
}
</script>

<style>

</style>
