import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/ventana/:dataprovider/:idParent?/:titulo?',
      name: 'ventana',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "tables" */ './views/WPanel.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
    },
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/full-page',
      component: () => import(/* webpackChunkName: "full-page" */ './views/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Login.vue')
        },
        {
          path: '/password-recovery',
          name: 'password-recovery',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/PasswordRecovery.vue')
        },
        {
          path: '/error-in-card',
          name: 'error-in-card',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue')
        },
        {
          path: '/error-simple',
          name: 'error-simple',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue'),
          props: { isInCard: false }
        },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/LockScreen.vue')
        }
      ]
    },
    {
      path: '/importar-calendario',
      name: 'WImportCalendario',
      component: () => import('./components/template/WImportCalendario.vue')
    },
    {
      path: '/importar-redexis',
      name: 'WImportRedexis',
      component: () => import('./components/template/WImportRedexis.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
