<template>
  <aside
      v-show="isAsideVisible"
      class="aside is-placed-left"
      :class="{'is-expanded':isAsideExpanded || isSecondary, 'is-secondary':isSecondary}">
    <aside-tools :has-close="isSecondary" :icon="icon" @close="close">
      <router-link to="/" tag="span" v-if="!isSecondary">
        <template v-if="isAsideExpanded || isAsideMobileExpanded">
          <b>{{title}}</b>
        </template>
        <template v-else>
          <b>Whales</b>
        </template>
      </router-link>
      <span v-else-if="label">{{ label }}</span>
    </aside-tools>
    <div ref="menuContainer" class="menu-container" @mouseenter="psUpdate">
      <div class="menu is-menu-main" >
          <aside-menu-list
            :is-secondary="isSecondary"
            @menu-click="menuClick"
            :menu="menu"/>
      </div>
    </div>
    <div class="menu is-menu-bottom">
      <aside-menu-list :menu="menuBottom" @menu-click="menuClick"/>
    </div>
  </aside>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import { mapState } from 'vuex'
import AsideTools from '@/components/template/AsideTools'

export default {
  name: 'AsideMenu',
  components: { AsideTools },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    menuBottom: {
      type: Array,
      default: () => []
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState([
      'isAsideVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded'
    ]),
    title () {
      return process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : 'Whales Proyectos'
    }
  },
  mounted () {
    this.ps = new PerfectScrollbar(this.$refs.menuContainer)
  },
  methods: {
    menuClick (item) {
      this.$emit('menu-click', item)
    },
    psUpdate () {
      if (this.ps) {
        this.ps.update()
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
