<template>
  <line-chart
    :chartData="data"
    :options="chartOptions"
  />
</template>

<script lang="ts">
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'LineChartComponent',
  components: {
    LineChart: Line
  },
  props: {
    chartData: {
      type: Array,
      default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      backgroundColorsList: ['#F14D3D', '#FFC300', '#FF9933', '#53D83B', '#3B76D8', '#B73BD8', '#D83B5C'],
      backgroundColorsUsed: [],
      data: {}
    }
  },
  async mounted () {
    this.data = await this.getData(this.chartData, this.chartOptions)
  },
  methods: {
    getData (data, options) {
      try {
        const resultado = { labels: [], datasets: [] }
        const datasets = options.header
        if (!data || !datasets) { return resultado }
        // Solo puede haber un label, el resto seran ignorados
        const labelName = datasets.find(item => item.type === 'label')['field']
        resultado.labels = data.map(item => {
          return item[labelName]
        })
        if (!resultado.labels) { return resultado }
        resultado.datasets = datasets.filter(x => x.type === 'dataset').map(dataset => {
          return {
            label: dataset.label,
            backgroundColor: this.getBackgroundColors(dataset.backgroundColor),
            data: data.map(item => parseFloat(item[dataset.field])) // TODO: Adaptarlo para que funcione por varios datasets (igual que el grafico de barras multiples)
          }
        })
        return resultado
      } catch (error) {
        // console.error(error)
        return { labels: [], datasets: [] }
      }
    },
    getBackgroundColors (backgroundColor) {
      if (backgroundColor && !this.backgroundColorsList.includes(backgroundColor)) {
        this.backgroundColorsUsed.push(backgroundColor)
        return backgroundColor
      }
      return this.backgroundColorsList.find(item => {
        if (!this.backgroundColorsUsed.includes(item)) {
          this.backgroundColorsUsed.push(item)
          return item
        }
      })
    }
  }
}
</script>
