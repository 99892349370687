<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  extends: Bar,
  components: {
    Bar
  },
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    },
    chartOptions: {
      type: Object,
      required: true,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    console.info(this.chartOptions)
  }
}
</script>
