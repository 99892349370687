<template>
    <b-field>
        <b-datepicker v-model="dates" :first-day-of-week="1" range size="is-small">
            <!--<form id="optionsDayRange">
              <button class="button is-danger" @click="dates = []">
                <b-icon icon="close"></b-icon>
                <span>Clear</span>
              </button>
            </form>-->
        </b-datepicker>
    </b-field>
</template>

<script>
import moment from 'moment'
export default {
  name: 'WDayRangeFilter',
  data () {
    return {
      dates: []
    }
  },
  props: {
    value: Array
  },
  watch: {
    value: function (valor) {
      if (valor === undefined) {
        this.clear()
      }
    },
    dates: function (valor) {
      if (valor && valor.length === 2) {
        this.$emit('input', [
          moment(valor[0]).format('DD/MM/YYYY'),
          moment(valor[1]).format('DD/MM/YYYY')
        ])
      } else {
        this.$emit('input', [])
      }
    }
  },
  methods: {
    clear () {
      this.dates = []
    }
  }
}
</script>
