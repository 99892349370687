<template>
  <div id="app" class="max-height">
    <nav-bar/>
    <aside-menu
      :menu="menuFiltered"
      @menu-click="menuClick"
      :class="{'has-secondary':!!menuSecondary}" />
    <aside-menu
      v-if="menuSecondary"
      :menu="menuSecondary"
      :is-secondary="true"
      :label="menuSecondaryLabel"
      :icon="menuSecondaryIcon"
      @menu-click="menuClick"
      @close="menuSecondaryCloseClick"/>
    <router-view/>
    <aside-right/>
    <footer-bar/>
    <overlay/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import axios from 'axios'
import NavBar from '@/components/template/NavBar'
import AsideMenu from '@/components/template/AsideMenu'
import FooterBar from '@/components/template/FooterBar'
import Overlay from '@/components/template/Overlay'
import AsideRight from '@/components/template/AsideRight'

export default {
  name: 'App',
  components: {
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar
  },
  data () {
    return {
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null,
      menu: [],
      almacenes: []
    }
  },
  computed: {
    ...mapState([
      'isOverlayVisible',
      'permisos'
    ]),
    menuFiltered () {
      return this.menu.filter(el => {
        return this.permisos.some(f => {
          return f === el.permiso
        })
      })
    }
  },
  created () {
    /* this.$store.commit('user', {
      name: 'John Doe',
      email: 'john@example.com',
      avatar: '/data-sources/avatars/annie-spratt-121576-unsplash.jpg'
    }) */
    this.$store.dispatch('tryAutoLogin')
  },
  mounted () {
    this.getMenus()
  },
  methods: {
    getMenus () {
      axios
        .get('data-sources/menus.json')
        .then(r => {
          this.menu = r.data.subMenus
          // this.menu = r.data.subMenus.filter( m => m.menu)
        })
        .catch(err => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger'
          })
        })
    },
    menuClick (item) {
      if (item.menuSecondary) {
        this.menuSecondary = item.menuSecondary
        this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
        this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

        this.$store.commit('asideActiveForcedKeyToggle', item)
        this.$store.commit('overlayToggle', true)
      } else if (item.action && item.action === 'logout') {
        this.$buefy.toast.open({
          message: 'Log out clicked',
          type: 'is-danger',
          queue: false
        })
      }
    },
    menuSecondaryCloseClick () {
      this.$store.commit('overlayToggle', false)
    },
    menuSecondaryClose () {
      this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
      this.$store.commit('asideActiveForcedKeyToggle', null)
    }
  },
  watch: {
    isOverlayVisible (newValue) {
      if (!newValue) {
        this.menuSecondaryClose()
      }
    }
  }
}
</script>
