<template>
  <div>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    chartOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      backgroundColors: [],
      chartData: {}
    }
  },
  async beforeMount () {
    this.chartData = await this.getData(this.data, this.chartOptions)
  },
  methods: {
    getData (data, options) {
      try {
        const resultado = { labels: [], datasets: [] }
        const datasets = options.header
        const chartData = datasets.find(x => x.type === 'dataset')['field']
        const labelName = datasets.find(item => item.type === 'label')['field']
        const groupName = datasets.find(x => x.type === 'group')['field']
        const groups = [...new Set(data.map(x => x[groupName]))]
        const colors = [...new Set(data.map(x => x['color']))]
        // Solo puede haber un label, el resto seran ignorados
        resultado.labels = [...new Set(data.map(x => x[labelName]))]
        // Se obtienen los grupos
        resultado.datasets = groups.map(group => {
          return {
            label: group,
            backgroundColor: this.getBackgroundColors(colors.find(color => data.find(x => x[groupName] === group && x['color'] === color))),
            data: resultado.labels.map(label => {
              let index = data.findIndex(x => x[groupName] === group && x[labelName] === label)
              if (index > -1) { return data[index][chartData] }
              return 0
            })
          }
        })
        return resultado
      } catch (error) {
        // console.error(error)
        return { labels: [], datasets: [] }
      }
    },
    getBackgroundColors (backgroundColor) {
      try {
        if (!backgroundColor) {
          return null
        }
        if (!this.backgroundColors.includes(backgroundColor)) {
          this.backgroundColors.push(backgroundColor)
          return backgroundColor
        }
        return null
      } catch (e) {
        return null
      }
    }
  }
}
</script>
